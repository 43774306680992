@import '~bulma/bulma';
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@400;700&display=swap');

* {
  font-family: 'M PLUS 1p', sans-serif;
}

header{
  .logo{
    max-width:200px;
    margin-left:5px;
    margin-bottom:12px;
  }

  padding:15px;
  background: linear-gradient(to right, #FF6FD0, #9A83C8);
}

.body{
  padding:10px;
  h3{
    padding-bottom:10px;
  }
}

.list{
  padding:10px;
}

.timepicker{
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  span{
    padding:10px;
  }
  .select{
    padding-right:4px;
  }
  input[type=date]{
    flex: 2;
    margin-right:3px;
  }
  input[type=time]{
    flex: 1;
  }
}
