
div.date{
  display: inline-block;
  width:60px;
  text-align: center;

  span.year, span.month, span.day{
    display: block;
    margin:0 auto;
  }

  span.year{
    font-size:11px;
    color:#999;
  }

  div.cal{
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    margin:0 auto;
    background: rgb(255,255,255);
    background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(251,251,251,1) 100%);

    border:1px solid #ddd;
    border-radius: 5px;

    span.month{
      background: rgb(236,159,255);
      background: linear-gradient(135deg, rgba(236,159,255,1) 0%, rgba(225,101,255,1) 100%);
      color: white;
      font-weight: bold;
      font-size:12px;
      line-height:20px
    }
    span.day{
      line-height:40px;
      font-size:24px;
      font-weight: 800;
    }
  }
}

.schedule-item{
  width:100%;

  padding: 5px;
  background: rgb(255,255,255);
  background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(251,251,251,1) 100%);

  border-radius: 5px;
  border: 1px solid #ddd;
  margin:10px 0;
  display: flex;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

}

.time{
 flex:1;
  padding:10px 20px;
  padding-top:15px;
  font-weight: bold;
  font-size:18px;
}
.trash{
  width:60px;
  padding:10px;
  button{
    padding:10px;
    background: orange;
    border: 0;
  }
}
